import React from "react";
import classes from './TopConditions.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/HomePage/top_conditions.avif';
import { Fade } from "react-reveal";

const list = [
    'home.top_conditions.li_01',
    'home.top_conditions.li_02',
    'home.top_conditions.li_03',
    'home.top_conditions.li_04',
    'home.top_conditions.li_05',
];

const TopConditions = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h2 className="font-64">
                        {t('home.top_conditions.title')}
                    </h2>
                    <p className={`${classes.text} font-20`}>
                        {t('home.top_conditions.text')}
                    </p>
                    <ul className={`${classes.list} font-16`}>
                        {list.map((li, index) =>
                            <li key={index} className={classes.item}>
                                {t(li)}
                            </li>
                        )}
                    </ul>
                </div>
                <Fade right>
                    <img className={classes.image} src={image} alt='' />
                </Fade>
            </div>
        </section>
    );
};

export default TopConditions;

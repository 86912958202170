import React from "react";
import classes from './Input.module.scss';

const Textarea = ({
    value, 
    setValue,
    placeholder = "",
}) => {
    return (
        <textarea
            placeholder={placeholder}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className={classes.textarea}
        />
    );
};

export default Textarea;
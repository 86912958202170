import React, { useEffect, useState } from "react";
import classes from './Accounts.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import sphere_01 from '../../../assets/img/AccountsPage/sphere_01.webp';
import sphere_02 from '../../../assets/img/AccountsPage/sphere_02.webp';
import { Fade } from "react-reveal";

const Card = ({ card, index }) => {
    const { t } = useTranslation();
    return (
        <div className={`${classes.card} ${card.classes}`}>
            <div className={classes.content}>
                <h4 className={`${classes.title} font-36`}>
                    {card.title}
                </h4>
                <p className={`${classes.text} font-16`}>
                    {t(card.text)}
                </p>
                <div className={`${classes.rows} font-16`}>
                    {card.rows.wo_comm_wrap &&
                        <div className={`${classes.row} ${classes.rowLine}`}>
                            <div className={classes.rowTitle}>
                                {t('accounts.accounts.row.without_commission_swap')}
                            </div>
                        </div>
                    }
                    {card.rows.wo_comm &&
                        <div className={`${classes.row} ${classes.rowLine}`}>
                            <div className={classes.rowTitle}>
                                {t('accounts.accounts.row.without_commission')}
                            </div>
                        </div>
                    }
                    <div className={classes.row}>
                        <div className={classes.rowTitle}>
                            {t('accounts.accounts.row.minimum_deposit')}
                        </div>
                        <div className={classes.rowText}>
                            {card.rows.min_dep}
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.rowTitle}>
                            {t('accounts.accounts.row.account_currency')}
                        </div>
                        <div className={classes.rowText}>
                            {card.rows.acc_currency}
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.rowTitle}>
                            {t('accounts.accounts.row.leverage')}
                        </div>
                        <div className={classes.rowText}>
                            {card.rows.leverage}
                        </div>
                    </div>
                    {card.rows.expers_adv &&
                        <div className={classes.row}>
                            <div className={classes.rowTitle}>
                                {t('accounts.accounts.row.expert_advisor')}
                            </div>
                            <div className={classes.rowText}>
                                <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_1624_1519)">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6653 2.33473C16.1116 2.78105 16.1116 3.50467 15.6653 3.95099L6.52242 13.0938C6.0761 13.5401 5.35249 13.5401 4.90617 13.0938L0.334732 8.52241C-0.111577 8.07612 -0.111577 7.35249 0.334732 6.90617C0.781053 6.45986 1.50467 6.45986 1.95099 6.90617L5.7143 10.6695L14.0491 2.33473C14.4953 1.88842 15.219 1.88842 15.6653 2.33473Z" fill="#000" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1624_1519">
                                            <rect width="16" height="16" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>

                            </div>
                        </div>
                    }
                    <div className={classes.row}>
                        <div className={classes.rowTitle}>
                            {t('accounts.accounts.row.annual_daily_profit')}
                        </div>
                        <div className={classes.rowText}>
                            {card.rows.ann_profit}
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.rowTitle}>
                            {t('accounts.accounts.row.trading_symbols')}
                        </div>
                        <div className={classes.rowText}>
                            {card.rows.trading_sym}
                        </div>
                    </div>
                    {card.rows.all_srt_allowed &&
                        <div className={classes.row}>
                            <div className={classes.rowTitle}>
                                {t('accounts.accounts.row.trading_signals')}
                            </div>
                            <div className={classes.rowText}>
                                {card.rows.all_srt_allowed}
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className={classes.btn}>
                <Button dark={(index === 2 || index === 3) ? true : false} />
            </div>
        </div>
    );
};

const Accounts = () => {
    const { t } = useTranslation();
    const [swiper, setSwiper] = useState({});
    const [active, setActive] = useState(0);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const cards = [
        {
            classes: classes.card_01,
            title: 'Standard',
            text: 'accounts.accounts.text_01',
            rows: {
                min_dep: '1,000$',
                acc_currency: 'USD',
                leverage: '1:500',
                expers_adv: true,
                ann_profit: '0,5%',
                trading_sym: t('accounts.accounts.row.international'),
            },
        },
        {
            classes: classes.card_02,
            title: 'Premium',
            text: 'accounts.accounts.text_02',
            rows: {
                wo_comm_wrap: true,
                min_dep: '10,000$',
                acc_currency: 'USD',
                leverage: '1:500',
                expers_adv: true,
                ann_profit: '2%',
                trading_sym: t('accounts.accounts.row.international'),
            },
        },
        {
            classes: classes.card_03,
            title: 'VIP',
            text: 'accounts.accounts.text_03',
            rows: {
                wo_comm_wrap: true,
                min_dep: '25,000$',
                acc_currency: 'USD',
                leverage: '1:500',
                expers_adv: true,
                ann_profit: '2%',
                trading_sym: t('accounts.accounts.row.international'),
            },
        },
        {
            classes: classes.card_04,
            title: 'PRO',
            text: 'accounts.accounts.text_04',
            rows: {
                wo_comm_wrap: true,
                min_dep: '50,000$',
                acc_currency: 'USD',
                leverage: '1:500',
                ann_profit: '3,5%',
                trading_sym: t('accounts.accounts.row.international'),
                all_srt_allowed: t('accounts.accounts.row.all_straregies_allowed'),
            },
        },
    ];

    return (
        <section className="mt container">
            <div className={classes.wrap}>
                {windowWidth > 870 ?
                    <div className={classes.cards}>
                        {cards.map((card, index) =>
                            <Card card={card} key={index} index={index} />
                        )}
                    </div>
                    :
                    <div className={classes.swiperWrap}>
                        <Swiper
                            modules={[Navigation]}
                            className="accountsSwiper no-select"
                            grabCursor
                            spaceBetween={20}
                            slidesPerView={1}
                            onInit={(e) => { setSwiper(e) }}
                            onSlideChange={(e) => setActive(e.activeIndex)}
                        >
                            {cards.map((card, index) =>
                                <SwiperSlide key={index} className="accountsSwiperSlide">
                                    <Card card={card} key={index} index={index} />
                                </SwiperSlide>
                            )}
                        </Swiper>
                        <div className={classes.nav}>
                            <div
                                className={`${classes.navBtn} ${classes.left} ${active === 0 ? classes.inactive : ''}`}
                                onClick={() => swiper.slidePrev()}
                            >
                                <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M5 12H19" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12 5L19 12L12 19" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div
                                className={`${classes.navBtn} ${active === swiper.snapGrid?.length - 1 ? classes.inactive : ''}`}
                                onClick={() => swiper.slideNext()}
                            >
                                <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M5 12H19" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12 5L19 12L12 19" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </div>
                    </div>
                }
                {windowWidth > 1220 &&
                    <>
                        <Fade left>
                            <img className={classes.sphere_01} src={sphere_01} alt='' />
                        </Fade>
                        <Fade right>
                            <img className={classes.sphere_02} src={sphere_02} alt='' />
                        </Fade>
                    </>
                }
            </div>
        </section>
    );
};

export default Accounts;

import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from "../components/PartnershipPage/Banner/Banner";
import ChooseOption from "../components/PartnershipPage/ChooseOption/ChooseOption";
import Benefits from "../components/PartnershipPage/Benefits/Benefits";
import GetTouch from "../components/HomePage/GetTouch/GetTouch";

const PartnershipPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Lumina · Partnership</title>
        <meta name="title" content="Lumina Partnership - Grow with Us" />
        <meta property="og:title" content="Lumina Partnership - Grow with Us" />
        <meta property="twitter:title" content="Lumina Partnership - Grow with Us" />

        <meta name="description" content="Explore partnership opportunities with Lumina. Join our network and benefit from lucrative collaborations. Whether you're an affiliate, introducing broker, or institutional partner, discover the advantages of partnering with Lumina." />
        <meta property="og:description" content="Explore partnership opportunities with Lumina. Join our network and benefit from lucrative collaborations. Whether you're an affiliate, introducing broker, or institutional partner, discover the advantages of partnering with Lumina." />
        <meta property="twitter:description" content="Explore partnership opportunities with Lumina. Join our network and benefit from lucrative collaborations. Whether you're an affiliate, introducing broker, or institutional partner, discover the advantages of partnering with Lumina." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <ChooseOption />
        <Benefits />
        <GetTouch />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default PartnershipPage;

import React from "react";
import classes from './TradingProducts.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/HomePage/trading_products.avif';
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const TradingProducts = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-64`}>
                        {t('home.trading_products.title')}
                    </h2>
                    <p className={`${classes.text} font-16`}>
                        {t('home.trading_products.text')}
                    </p>
                    <div className={`${classes.btn} mt-btn`}>
                        <Button>
                            {t('btn.see_more')}
                        </Button>
                    </div>
                </div>
                <Fade right>
                    <img className={classes.image} src={image} alt='' />
                </Fade>
            </div>
        </section>
    );
};

export default TradingProducts;

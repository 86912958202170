import React, { useState } from "react";
import classes from './Underbanner.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/ToolsPage/underbanner.avif';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import { Fade } from "react-reveal";

const cards = [
    {
        title: 'tools.underbanner.card_01.title',
        text: 'tools.underbanner.card_01.text',
    },
    {
        title: 'tools.underbanner.card_02.title',
        text: 'tools.underbanner.card_02.text',
    },
    {
        title: 'tools.underbanner.card_03.title',
        text: 'tools.underbanner.card_03.text',
    },
    {
        title: 'tools.underbanner.card_04.title',
        text: 'tools.underbanner.card_04.text',
    },
    {
        title: 'tools.underbanner.card_05.title',
        text: 'tools.underbanner.card_05.text',
    },
    {
        title: 'tools.underbanner.card_06.title',
        text: 'tools.underbanner.card_06.text',
    },
];

const Underbanner = () => {
    const { t } = useTranslation();
    const [swiper, setSwiper] = useState({});
    const [active, setActive] = useState(0);
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.image}>
                    <Fade left>
                        <img className={classes.img} src={image} alt='' />
                    </Fade>
                </div>
                <div className={classes.content}>
                    <div className={classes.swiperWrap}>
                        <Swiper
                            modules={[Navigation]}
                            className="toolsUnderbannerSwiper no-select"
                            grabCursor
                            spaceBetween={20}
                            slidesPerView={1}
                            onInit={(e) => { setSwiper(e) }}
                            onSlideChange={(e) => setActive(e.activeIndex)}
                        >
                            {cards.map((card, index) =>
                                <SwiperSlide key={index} className="toolsUnderbannerSwiperSlide">
                                    <div className={`
                                        ${classes.card} 
                                        ${!(index % 2) ? classes.light : classes.dark}    
                                    `}>
                                        <h4 className="font-36">
                                            {t(card.title)}
                                        </h4>
                                        <p className={`${classes.cardText} font-16`}>
                                            {t(card.text)}
                                        </p>
                                    </div>
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </div>
                    <div className={classes.nav}>
                        <div
                            className={`${classes.navBtn} ${classes.left} ${active === 0 ? classes.inactive : ''}`}
                            onClick={() => swiper.slidePrev()}
                        >
                            <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M5 12H19" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12 5L19 12L12 19" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <div
                            className={`${classes.navBtn} ${active === swiper.snapGrid?.length - 1 ? classes.inactive : ''}`}
                            onClick={() => swiper.slideNext()}
                        >
                            <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M5 12H19" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12 5L19 12L12 19" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Underbanner;

import React from "react";
import classes from './HowWorks.module.scss';
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

const cards = [
    {
        title: 'home.how_works.card_01.title',
        text: 'home.how_works.card_01.text',
    },
    {
        title: 'home.how_works.card_02.title',
        text: 'home.how_works.card_02.text',
    },
    {
        title: 'home.how_works.card_03.title',
        text: 'home.how_works.card_03.text',
    },
];

const HowWorks = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-64`}>
                    {t('home.how_works.title')}
                </h2>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <Fade key={index} bottom>
                            <div className={classes.card}>
                                <h4 className={`${classes.cardTitle} font-36`}>
                                    {t(card.title)}
                                </h4>
                                <p className={`${classes.cardText} font-16`}>
                                    {t(card.text)}
                                </p>
                                <div className={classes.cardNum}>
                                    0{index + 1}
                                </div>
                            </div>
                        </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default HowWorks;

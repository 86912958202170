import React from "react";
import classes from './Funding.module.scss';
import { useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/ToolsPage/Funding/image_01.png';
import image_02 from '../../../assets/img/ToolsPage/Funding/image_02.png';
import { Fade } from "react-reveal";

const cards = [
    {
        title: 'tools.funding.card_01.title',
        text: 'tools.funding.card_01.text',
    },
    {
        title: 'tools.funding.card_02.title',
        text: 'tools.funding.card_02.text',
    },
    {
        image: image_02,
        title: 'tools.funding.card_03.title',
        text: 'tools.funding.card_03.text',
    },
    {
        image: image_01,
        title: 'tools.funding.card_04.title',
        text: 'tools.funding.card_04.text',
    },
];

const Funding = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-64`}>
                    {t('tools.funding.title')}
                </h2>
                <div className={`${classes.text} font-16`}>
                    <p>
                        {t('tools.funding.text_01')}
                    </p>
                    <p>
                        {t('tools.funding.text_02')}
                    </p>
                </div>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <Fade
                            key={index}
                            left={(index === 0 || index === 3) ? true : false}
                            right={(index === 1 || index === 2) ? true : false}
                        >
                            <div className={classes.card}>
                                <h4 className={`${classes.cardTitle} font-32`}>
                                    {t(card.title)}
                                </h4>
                                <p className={`${classes.cardText} font-16`}>
                                    {t(card.text)}
                                </p>
                                {card.image &&
                                    <img className={classes.cardImage} src={card.image} alt='' />
                                }
                            </div>
                        </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Funding;

import React from "react";
import classes from './Commits.module.scss';
import { useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/AboutPage/Commits/image_01.webp';
import image_02 from '../../../assets/img/AboutPage/Commits/image_02.webp';
import { Fade } from "react-reveal";

const Commits = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-64`}>
                    {t('about.commits.title')}
                </h2>
                <div className={`${classes.block} ${classes.block_01}`}>
                    <div className={classes.content}>
                        <h4 className={`${classes.subtitle} font-36`}>
                            {t('about.commits.block_01.subtitle')}
                        </h4>
                        <p className={`${classes.text_01} font-20`}>
                            {t('about.commits.block_01.text_01')}
                        </p>
                        <p className={`${classes.text_02} font-20`}>
                            {t('about.commits.block_01.text_02')}
                        </p>
                        <ul className={`${classes.list} font-16`}>
                            <li className={classes.item}>
                                {t('about.commits.block_01.li_01')}
                            </li>
                            <li className={classes.item}>
                                {t('about.commits.block_01.li_02')}
                            </li>
                        </ul>
                    </div>
                    <div className={classes.image}>
                        <Fade right>
                            <img src={image_01} alt='' />
                        </Fade>
                    </div>
                </div>
                <div className={`${classes.block} ${classes.block_02}`}>
                    <div className={classes.image}>
                        <Fade left>
                            <img src={image_02} alt='' />
                        </Fade>
                    </div>
                    <div className={classes.content}>
                        <h4 className={`${classes.subtitle} font-36`}>
                            {t('about.commits.block_02.subtitle')}
                        </h4>
                        <div className={`${classes.text} font-20`}>
                            <p>
                                {t('about.commits.block_02.text_01')}
                            </p>
                            <p>
                                {t('about.commits.block_02.text_02')}
                            </p>
                            <p>
                                {t('about.commits.block_02.text_03')}
                            </p>
                            <p>
                                {t('about.commits.block_02.text_04')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Commits;

import React from "react";
import classes from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image from '../../../assets/img/AccountsPage/banner.avif';
import { Fade } from "react-reveal";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.container}>
            <div className="container">
                <div className={classes.wrap}>
                    <div className={classes.content}>
                        <h1 className={`${classes.title} font-48`}>
                            <Trans>
                                {t('accounts.banner.title')}
                            </Trans>
                        </h1>
                        <div className={`${classes.text} font-20`}>
                            <p>
                                {t('accounts.banner.text_01')}
                            </p>
                            <p>
                                {t('accounts.banner.text_02')}
                            </p>
                        </div>
                        <h4 className={`${classes.subtitle} font-24`}>
                            {t('accounts.banner.subtitle')}
                        </h4>
                    </div>
                </div>
            </div>
            <Fade right>
                <img className={classes.image} src={image} alt='' />
            </Fade>
        </section>
    );
};

export default Banner;

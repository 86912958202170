import _ from 'lodash';
import React, { useEffect, useState, useRef } from "react";
import classes from "./Header.module.scss";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import i18n from "i18next";
import { headerRoutes } from "../../../router";
import Button from "../Button/Button";
import $ from "jquery";
import logo from "../../../assets/img/logo.svg";

const languages = [
  { value: "en", label: "EN" },
  { value: "fr", label: "FR" },
  { value: "cs", label: "CS" },
];

const Header = () => {
  const { t } = useTranslation();
  const [burgerOpened, setBurgerOpened] = useState(false);
  const { pathname } = useLocation();
  const { lang } = useParams();
  const navigate = useNavigate();
  const headerRef = useRef(null);

  const setLocale = (lang) => {
    i18n.changeLanguage(lang);
    navigate(`/${lang}/${_.last(pathname.split('/'))}`);
  };

  const listenScroll = () => {
    if (window.pageYOffset > 70) {
      $(headerRef.current).removeClass(classes.hideBg);
    } else {
      $(headerRef.current).addClass(classes.hideBg);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    const langs = _.dropRight(i18n.options.supportedLngs);
    if (lang === undefined || !langs.includes(lang)) {
      setLocale('en');
    }

    window.addEventListener("scroll", (event) => {
      listenScroll();
    });
    listenScroll();

    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    // listenScroll();
    burgerOpened
      ? $("body").addClass("lock")
      : $("body").removeClass("lock");
  }, [burgerOpened]);

  useEffect(() => {
    $("body").hasClass("lock") &&
      $("body").removeClass("lock");
  }, []);

  useEffect(() => {
    const langs = _.dropRight(i18n.options.supportedLngs);
    if (lang === undefined || !langs.includes(lang)) {
      setLocale("en");
    } else {
      setLocale(lang);
    }
    // eslint-disable-next-line
  }, []);

  window.addEventListener("resize", (event) => {
    setBurgerOpened(false);
  });

  return (
    <div
      ref={headerRef}
      className={`${classes.header} ${burgerOpened && classes.headerActive} ${classes.hideBg} no-select`}
    >
      <div className={`container ${classes.container}`}>
        <div className={classes.headerRow}>
          <div className={classes.headerRowLogo}>
            <Link to={`/${lang}/`}>
              <img src={logo} alt="" />
            </Link>
          </div>
          <div className={classes.headerRowLinks}>
            {headerRoutes.map((route, index) => (
              <Link
                key={index}
                to={`/${lang}/${route.path}`}
                className={`${route.path === _.last(pathname.split('/')) ? classes.linkActive : ''}`}
              >
                {t(route.text)}
              </Link>
            ))}
          </div>
          <div className={classes.headerRowRight}>
            <div className={classes.langs}>
              <Dropdown
                options={languages}
                onChange={(e) => setLocale(e.value)}
                value={lang}
                placeholder="EN"
              />
            </div>
            <a
              href={`${process.env.REACT_APP_CABINET_URL}/login`}
              className={classes.login}
            >
              {t('btn.login')}
            </a>
            <div
              onClick={() => setBurgerOpened((value) => !value)}
              className={`${classes.burger} ${burgerOpened ? classes.burgerActive : ''}`}
            ></div>
          </div>
        </div>
      </div>
      <div className={`${classes.mobileMenu} ${burgerOpened ? classes.mobileMenuActive : ''}`}>
        <div className="container">
          {headerRoutes.map((route, index) => (
            <Link
              key={index}
              to={`/${lang}/${route.path}`}
              className={`${route.path === _.last(pathname.split('/')) && classes.linkActive} ${classes.aLink}`}
              onClick={() => setBurgerOpened(false)}
            >
              {t(route.text)}
            </Link>
          ))}
          <div className={classes.btn}>
            <Button login>
              {t('btn.login')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
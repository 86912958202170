import React from "react";
import classes from './TradersChoose.module.scss';
import { useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/AboutPage/TradersChoose/image_01.svg';
import image_02 from '../../../assets/img/AboutPage/TradersChoose/image_02.svg';
import image_03 from '../../../assets/img/AboutPage/TradersChoose/image_03.svg';
import { Fade } from "react-reveal";

const cards = [
    {
        image: image_01,
        title: 'about.traders_choose.card_01.title',
        text: 'about.traders_choose.card_01.text',
    },
    {
        image: image_02,
        title: 'about.traders_choose.card_02.title',
        text: 'about.traders_choose.card_02.text',
    },
    {
        image: image_03,
        title: 'about.traders_choose.card_03.title',
        text: 'about.traders_choose.card_03.text',
    },
];

const TradersChoose = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-36`}>
                    {t('about.traders_choose.title')}
                </h2>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <Fade key={index} bottom>
                            <div className={classes.card}>
                                <h4 className={`${classes.cardTitle} font-36`}>
                                    {t(card.title)}
                                </h4>
                                <p className={`${classes.cardText} font-16`}>
                                    {t(card.text)}
                                </p>
                                <img className={classes.cardImage} src={card.image} alt='' />
                            </div>
                        </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default TradersChoose;

import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from "../components/AccountsPage/Banner/Banner";
import Accounts from "../components/AccountsPage/Accounts/Accounts";

const AccountsPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Lumina · Account Types</title>
        <meta name="title" content="Lumina Account Types - Tailored for Your Success" />
        <meta property="og:title" content="Lumina Account Types - Tailored for Your Success" />
        <meta property="twitter:title" content="Lumina Account Types - Tailored for Your Success" />

        <meta name="description" content="Choose from a range of account types at Lumina, designed to meet the needs of traders at every level. Unlock exclusive features, benefits, and personalized support. Select your account type and take control of your financial future." />
        <meta property="og:description" content="Choose from a range of account types at Lumina, designed to meet the needs of traders at every level. Unlock exclusive features, benefits, and personalized support. Select your account type and take control of your financial future." />
        <meta property="twitter:description" content="Choose from a range of account types at Lumina, designed to meet the needs of traders at every level. Unlock exclusive features, benefits, and personalized support. Select your account type and take control of your financial future." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <Accounts />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default AccountsPage;

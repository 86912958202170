import React, { useState } from "react";
import classes from './SeeWhat.module.scss';
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import icon_01 from '../../../assets/img/AboutPage/SeeWhat/icon_01.png';
import icon_02 from '../../../assets/img/AboutPage/SeeWhat/icon_02.png';
import icon_03 from '../../../assets/img/AboutPage/SeeWhat/icon_03.png';
import icon_04 from '../../../assets/img/AboutPage/SeeWhat/icon_04.png';
import icon_05 from '../../../assets/img/AboutPage/SeeWhat/icon_05.png';
import stars_01 from '../../../assets/img/AboutPage/SeeWhat/stars_01.png';
import stars_02 from '../../../assets/img/AboutPage/SeeWhat/stars_02.png';

const cards = [
    {
        stars: stars_01,
        icon: icon_01,
        title: '@ShadowSpark',
        text: 'about.see_what.card_01.text'
    },
    {
        stars: stars_01,
        icon: icon_02,
        title: '@LunaWhisper',
        text: 'about.see_what.card_02.text'
    },
    {
        stars: stars_02,
        icon: icon_03,
        title: '@FrostByte',
        text: 'about.see_what.card_03.text'
    },
    {
        stars: stars_01,
        icon: icon_04,
        title: '@EmberEcho',
        text: 'about.see_what.card_04.text'
    },
    {
        stars: stars_01,
        icon: icon_05,
        title: '@SapphireDream',
        text: 'about.see_what.card_05.text'
    },
];

const SeeWhat = () => {
    const { t } = useTranslation();
    const [swiper, setSwiper] = useState({});
    const [active, setActive] = useState(0);
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-36`}>
                    {t('about.see_what.title')}
                </h2>
                <div className={classes.swiperWrap}>
                    <Swiper
                        modules={[Navigation]}
                        className="aboutSeeWhatSwiper no-select"
                        grabCursor
                        spaceBetween={20}
                        slidesPerView={1}
                        onInit={(e) => { setSwiper(e) }}
                        onSlideChange={(e) => setActive(e.activeIndex)}
                        breakpoints={{
                            700: {
                                slidesPerView: 2,
                            },
                            950: {
                                slidesPerView: 3,
                            },
                        }}
                    >
                        {cards.map((card, index) =>
                            <SwiperSlide key={index} className="aboutSeeWhatSwiperSlide">
                                <div className={`${classes.card} font-16`}>
                                    <div className={classes.cardHead}>
                                        <img className={classes.icon} src={card.icon} alt='' />
                                        <div className={classes.cardHeadContent}>
                                            <p className={classes.cardHeadTitle}>
                                                {card.title}
                                            </p>
                                            <img className={classes.stars} src={card.stars} alt='' />
                                        </div>
                                    </div>
                                    <p className={classes.cardText}>
                                        {t(card.text)}
                                    </p>
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
                <div className={classes.nav}>
                    <div
                        className={`${classes.navBtn} ${classes.left} ${active === 0 ? classes.inactive : ''}`}
                        onClick={() => swiper.slidePrev()}
                    >
                        <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M5 12H19" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M12 5L19 12L12 19" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    <div
                        className={`${classes.navBtn} ${active === swiper.snapGrid?.length - 1 ? classes.inactive : ''}`}
                        onClick={() => swiper.slideNext()}
                    >
                        <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M5 12H19" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M12 5L19 12L12 19" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SeeWhat;

import React from "react";
import classes from './Underbanner.module.scss';
import { useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/HomePage/Underbanner/image_01.webp';
import image_02 from '../../../assets/img/HomePage/Underbanner/image_02.webp';
import { Fade } from "react-reveal";

const blocks = [
    {
        image: image_01,
        title: 'home.underbanner.block_01.title',
        list: [
            'home.underbanner.block_01.li_01',
            'home.underbanner.block_01.li_02',
            'home.underbanner.block_01.li_03',
            'home.underbanner.block_01.li_04',
            'home.underbanner.block_01.li_05',
            'home.underbanner.block_01.li_06',
            'home.underbanner.block_01.li_07',
        ],
    },
    {
        image: image_02,
        title: 'home.underbanner.block_02.title',
        list: [
            'home.underbanner.block_02.li_01',
            'home.underbanner.block_02.li_02',
            'home.underbanner.block_02.li_03',
            'home.underbanner.block_02.li_04',
            'home.underbanner.block_02.li_05',
            'home.underbanner.block_02.li_06',
        ],
    },
];

const Underbanner = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                {blocks.map((block, index) =>
                    <Fade
                        key={index}
                        left={index === 0 ? true : false}
                        right={index === 1 ? true : false}
                    >
                        <div className={classes.block}>
                            <h2 className={`${classes.title} font-36`}>
                                {t(block.title)}
                            </h2>
                            <ul className={`${classes.list} font-16`}>
                                {block.list.map((li, liIndex) =>
                                    <li key={liIndex} className={classes.item}>
                                        {t(li)}
                                    </li>
                                )}
                            </ul>
                            <img className={classes.image} src={block.image} alt='' />
                        </div>
                    </Fade>
                )}
            </div>
        </section>
    );
};

export default Underbanner;

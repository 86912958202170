import HomePage from '../pages/HomePage';
import ToolsPage from '../pages/ToolsPage';
import AccountsPage from '../pages/AccountsPage';
import PartnershipPage from '../pages/PartnershipPage';
import ContactsPage from '../pages/ContactPage';
import AboutPage from '../pages/AboutPage';

export const routes = [
    { path: '/:lang/', element: HomePage },
    { path: '/:lang/tools', element: ToolsPage },
    { path: '/:lang/accounts', element: AccountsPage },
    { path: '/:lang/partnership', element: PartnershipPage },
    { path: '/:lang/contacts', element: ContactsPage },
    { path: '/:lang/about', element: AboutPage },
];


export const headerRoutes = [
    { path: 'tools', text: 'header.tools' },
    { path: 'accounts', text: 'header.accounts' },
    { path: 'partnership', text: 'header.partnership' },
    { path: 'contacts', text: 'header.contacts' },
    { path: 'about', text: 'header.about' },
];

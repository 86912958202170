import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from "../components/ContactPage/Banner/Banner";

const ContactPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Lumina · Contact Us</title>
        <meta name="title" content="Contact Lumina - We're Here to Assist You" />
        <meta property="og:title" content="Contact Lumina - We're Here to Assist You" />
        <meta property="twitter:title" content="Contact Lumina - We're Here to Assist You" />

        <meta name="description" content="Reach out to Lumina for any inquiries, support, or assistance. Our dedicated team is ready to provide timely and personalized solutions. Connect with us today and experience the unparalleled support of Lumina." />
        <meta property="og:description" content="Reach out to Lumina for any inquiries, support, or assistance. Our dedicated team is ready to provide timely and personalized solutions. Connect with us today and experience the unparalleled support of Lumina." />
        <meta property="twitter:description" content="Reach out to Lumina for any inquiries, support, or assistance. Our dedicated team is ready to provide timely and personalized solutions. Connect with us today and experience the unparalleled support of Lumina." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default ContactPage;

import React from "react";
import classes from './MakesDifference.module.scss';
import { Trans, useTranslation } from "react-i18next";
import { Fade } from "react-reveal";
import wave_01 from '../../../assets/img/AboutPage/MakesDifference/wave_01.svg';
import wave_02 from '../../../assets/img/AboutPage/MakesDifference/wave_02.svg';
import wave_03 from '../../../assets/img/AboutPage/MakesDifference/wave_03.svg';

const cards = [
    {
        image: wave_01,
        title: 'about.makes_difference.card_01.title',
        text: 'about.makes_difference.card_01.text',
    },
    {
        image: wave_02,
        title: 'about.makes_difference.card_02.title',
        text: 'about.makes_difference.card_02.text',
    },
    {
        image: wave_03,
        title: 'about.makes_difference.card_03.title',
        text: 'about.makes_difference.card_03.text',
    },
];

const MakesDifference = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-64`}>
                    <Trans>
                        {t('about.makes_difference.title')}
                    </Trans>
                </h2>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <Fade key={index} bottom>
                            <div className={classes.card}>
                                <h4 className={`${classes.cardTitle} font-36`}>
                                    {t(card.title)}
                                </h4>
                                <p className={`${classes.cardText} font-16`}>
                                    {t(card.text)}
                                </p>
                                <img className={classes.cardImage} src={card.image} alt='' />
                            </div>
                        </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default MakesDifference;

import React from "react";
import classes from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const list = [
    'partnership.banner.li_01',
    'partnership.banner.li_02',
    'partnership.banner.li_03',
];

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h1 className={`${classes.title} font-48`}>
                        <Trans>
                            {t('partnership.banner.title')}
                        </Trans>
                    </h1>
                    <p className={`${classes.text} font-24`}>
                        {t('partnership.banner.text')}
                    </p>
                    <div className={`${classes.btn} mt-btn`}>
                        <Button />
                    </div>
                </div>
                <ul className={classes.list}>
                    {list.map((li, index) =>
                        <Fade key={index} right>
                            <li className={`${classes.item} font-24`}>
                                <div className={classes.num}>
                                    {t('partnership.banner.step')} {index + 1}
                                </div>
                                <p className={classes.itemText}>
                                    {t(li)}
                                </p>
                            </li>
                        </Fade>
                    )}
                </ul>
            </div>
        </section>
    );
};

export default Banner;

import React from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/AboutPage/banner.avif';
import { Fade } from "react-reveal";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h1 className={`${classes.title} font-64-banner`}>
                        {t('about.banner.title')}
                    </h1>
                    <h2 className={`${classes.subtitle} font-24`}>
                        {t('about.banner.subtitle')}
                    </h2>
                    <div className={`${classes.text} font-20`}>
                        <p>
                            {t('about.banner.text_01')}
                        </p><p>
                            {t('about.banner.text_02')}
                        </p>
                    </div>
                </div>
                <Fade right>
                    <img className={classes.image} src={image} alt='' />
                </Fade>
            </div>
        </section>
    );
};

export default Banner;

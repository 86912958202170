import React from "react";
import classes from './WhyChoose.module.scss';
import { useTranslation } from "react-i18next";
import icon_01 from '../../../assets/img/HomePage/WhyChoose/icon_01.svg';
import icon_02 from '../../../assets/img/HomePage/WhyChoose/icon_02.svg';
import icon_03 from '../../../assets/img/HomePage/WhyChoose/icon_03.svg';
import icon_04 from '../../../assets/img/HomePage/WhyChoose/icon_04.svg';
import { Fade } from "react-reveal";

const cards = [
    {
        icon: icon_01,
        title: 'home.why_choose.card_01.title',
        text: 'home.why_choose.card_01.text',
    },
    {
        icon: icon_02,
        title: 'home.why_choose.card_02.title',
        text: 'home.why_choose.card_02.text',
    },
    {
        icon: icon_03,
        title: 'home.why_choose.card_03.title',
        text: 'home.why_choose.card_03.text',
    },
    {
        icon: icon_04,
        title: 'home.why_choose.card_04.title',
        text: 'home.why_choose.card_04.text',
    },
];

const WhyChoose = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-64`}>
                    {t('home.why_choose.title')}
                </h2>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <Fade
                            key={index}
                            left={(index === 0 || index === 2) ? true : false}
                            right={(index === 1 || index === 3) ? true : false}
                        >
                            <div className={classes.card}>
                                <img className={classes.icon} src={card.icon} alt='' />
                                <div className={classes.content}>
                                    <h4 className={`${classes.subtitle} font-36-20`}>
                                        {t(card.title)}
                                    </h4>
                                    <p className={`${classes.text} font-16`}>
                                        {t(card.text)}
                                    </p>
                                </div>
                            </div>
                        </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default WhyChoose;

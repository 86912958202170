import React from "react";
import classes from './List.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/ToolsPage/list.avif';
import { Fade } from "react-reveal";

const list = [
    'tools.list.li_01',
    'tools.list.li_02',
    'tools.list.li_03',
    'tools.list.li_04',
    'tools.list.li_05',
    'tools.list.li_06',
    'tools.list.li_07',
    'tools.list.li_08',
    'tools.list.li_09',
    'tools.list.li_10',
    'tools.list.li_11',
];

const List = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.image}>
                    <Fade left>
                        <img src={image} alt='' />
                    </Fade>
                </div>
                <ul className={`${classes.list} font-16`}>
                    {list.map((li, liIndex) =>
                        <li key={liIndex} className={classes.item}>
                            {t(li)}
                        </li>
                    )}
                </ul>
            </div>
        </section>
    );
};

export default List;

import React from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import image from '../../../assets/img/HomePage/Banner/image.webp';
import circle_01 from '../../../assets/img/HomePage/Banner/circle_01.webp';
import circle_02 from '../../../assets/img/HomePage/Banner/circle_02.webp';
import lines from '../../../assets/img/HomePage/Banner/lines.webp';
import { Fade } from "react-reveal";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.container}>
            <div className="container">
                <div className={classes.wrap}>
                    <div className={classes.content}>
                        <h1 className={`${classes.title} font-64-banner`}>
                            Lumina
                        </h1>
                        <h2 className={`${classes.subtitle} font-48`}>
                            {t('home.banner.subtitle')}
                        </h2>
                        <p className={`${classes.text_01} font-20`}>
                            {t('home.banner.text')}
                        </p>
                        <p className={`${classes.text_02} font-24`}>
                            {t('home.banner.subtext')}
                        </p>
                        <div className={`${classes.btn} mt-btn`}>
                            <Button />
                        </div>
                    </div>
                    <div className={classes.images}>
                        <Fade right>
                            <img className={classes.image} src={image} alt='' />
                        </Fade>
                        <Fade right>
                            <img className={classes.circle_01} src={circle_01} alt='' />
                        </Fade>
                        <Fade right>
                            <img className={classes.circle_02} src={circle_02} alt='' />
                        </Fade>
                        <Fade right>
                            <img className={classes.lines} src={lines} alt='' />
                        </Fade>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;

import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from "../components/ToolsPage/Banner/Banner";
import Underbanner from "../components/ToolsPage/Underbanner/Underbanner";
import MarketAccess from "../components/ToolsPage/MarketAccess/MarketAccess";
import List from "../components/ToolsPage/List/List";
import Funding from "../components/ToolsPage/Funding/Funding";
import GetTouch from "../components/HomePage/GetTouch/GetTouch";

const ToolsPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Lumina · Tools & Products</title>
        <meta name="title" content="Lumina Tools & Products - Precision at Your Fingertips" />
        <meta property="og:title" content="Lumina Tools & Products - Precision at Your Fingertips" />
        <meta property="twitter:title" content="Lumina Tools & Products - Precision at Your Fingertips" />

        <meta name="description" content="Explore our suite of advanced trading tools and diverse financial products. From dynamic charts to innovative trading features, Lumina provides the resources you need to make informed decisions. Elevate your trading experience with us." />
        <meta property="og:description" content="Explore our suite of advanced trading tools and diverse financial products. From dynamic charts to innovative trading features, Lumina provides the resources you need to make informed decisions. Elevate your trading experience with us." />
        <meta property="twitter:description" content="Explore our suite of advanced trading tools and diverse financial products. From dynamic charts to innovative trading features, Lumina provides the resources you need to make informed decisions. Elevate your trading experience with us." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <Underbanner />
        <MarketAccess />
        <List />
        <Funding />
        <GetTouch />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default ToolsPage;

import logo from '../assets/img/logo.svg';
import image_01 from '../assets/img/HomePage/Banner/circle_01.webp';
import image_02 from '../assets/img/HomePage/Banner/circle_02.webp';
import image_03 from '../assets/img/HomePage/Banner/image.webp';
import image_04 from '../assets/img/HomePage/Banner/lines.webp';

export const images = [
    logo,
    image_01,
    image_02,
    image_03,
    image_04,
];

import React from "react";
import classes from './MarketAccess.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image from '../../../assets/img/ToolsPage/market_access.webp';
import { Fade } from "react-reveal";

const MarketAccess = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-64`}>
                        <Trans>
                            {t('tools.market_access.title')}
                        </Trans>
                    </h2>
                    <p className={`${classes.text_01} font-16`}>
                        {t('tools.market_access.text_01')}
                    </p>
                    <h4 className={`${classes.subtitle} font-36`}>
                        {t('tools.market_access.subtitle')}
                    </h4>
                    <p className={`${classes.text_02} font-16`}>
                        {t('tools.market_access.text_02')}
                    </p>
                </div>
                <div className={classes.image}>
                    <Fade right>
                        <img src={image} alt='' />
                    </Fade>
                </div>
            </div>
        </section>
    );
};

export default MarketAccess;

import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from "../components/HomePage/Banner/Banner";
import Underbanner from "../components/HomePage/Underbanner/Underbanner";
import TradingProducts from "../components/HomePage/TradingProducts/TradingProducts";
import Platform from "../components/HomePage/Platform/Platform";
import TopConditions from "../components/HomePage/TopConditions/TopConditions";
import WhyChoose from "../components/HomePage/WhyChoose/WhyChoose";
import HowWorks from "../components/HomePage/HowWorks/HowWorks";
import GetTouch from "../components/HomePage/GetTouch/GetTouch";

const HomePage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Lumina</title>
        <meta name="title" content="Lumina - Your Gateway to Seamless Trading" />
        <meta property="og:title" content="Lumina - Your Gateway to Seamless Trading" />
        <meta property="twitter:title" content="Lumina - Your Gateway to Seamless Trading" />

        <meta name="description" content="Discover the power of Lumina, your trusted platform for secure and intuitive trading. Explore real-time market data, cutting-edge tools, and unparalleled support. Start your trading journey with Lumina today." />
        <meta property="og:description" content="Discover the power of Lumina, your trusted platform for secure and intuitive trading. Explore real-time market data, cutting-edge tools, and unparalleled support. Start your trading journey with Lumina today." />
        <meta property="twitter:description" content="Discover the power of Lumina, your trusted platform for secure and intuitive trading. Explore real-time market data, cutting-edge tools, and unparalleled support. Start your trading journey with Lumina today." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <Underbanner />
        <TradingProducts />
        <Platform />
        <TopConditions />
        <WhyChoose />
        <HowWorks />
        <GetTouch />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default HomePage;

import React from "react";
import classes from './Platform.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/HomePage/platform.webp';
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const Platform = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.container}>
            <div className="mt container">
                <div className={classes.wrap}>
                    <div className={classes.content}>
                        <Fade left>
                            <p className={`${classes.title} font-64`}>
                                {t('home.platform.title')}
                            </p>
                        </Fade>
                        <h2 className={`${classes.subtitle} font-36`}>
                            {t('home.platform.subtitle')}
                        </h2>
                        <p className={`${classes.text} font-16`}>
                            {t('home.platform.text')}
                        </p>
                        <div className={`${classes.btn} mt-btn`}>
                            <Button dark />
                        </div>
                    </div>
                    <img className={classes.image} src={image} alt='' />
                </div>
            </div>
        </section>
    );
};

export default Platform;

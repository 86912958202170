import React, { useEffect, useState } from "react";
import classes from './ChooseOption.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/PartnershipPage/ChooseOption/image_01.avif';
import image_02 from '../../../assets/img/PartnershipPage/ChooseOption/image_02.avif';
import icon_01 from '../../../assets/img/HomePage/WhyChoose/icon_01.svg';
import icon_02 from '../../../assets/img/HomePage/WhyChoose/icon_02.svg';
import icon_03 from '../../../assets/img/HomePage/WhyChoose/icon_03.svg';
import icon_04 from '../../../assets/img/HomePage/WhyChoose/icon_04.svg';
import Tab from "./Tab/Tab";
import Buttons from "./Buttons";

const tabs = [
    {
        image: image_01,
        button: 'partnership.choose_option.button_01',
        title: 'partnership.choose_option.tab_01.title',
        subtitle: 'partnership.choose_option.tab_01.subtitle',
        list: [
            'partnership.choose_option.tab_01.list.li_01',
            'partnership.choose_option.tab_01.list.li_02',
            'partnership.choose_option.tab_01.list.li_03',
            'partnership.choose_option.tab_01.list.li_04',
            'partnership.choose_option.tab_01.list.li_05',
            'partnership.choose_option.tab_01.list.li_06',
        ],
        cards: [
            'partnership.choose_option.tab_01.cards.card_01.text',
            'partnership.choose_option.tab_01.cards.card_02.text',
            'partnership.choose_option.tab_01.cards.card_03.text',
            'partnership.choose_option.tab_01.cards.card_04.text',
        ],
        block: {
            title: 'partnership.choose_option.tab_01.block.title',
            cards: [
                {
                    icon: icon_01,
                    title: 'partnership.choose_option.tab_01.block.card_01.title',
                    text: 'partnership.choose_option.tab_01.block.card_01.text',
                },
                {
                    icon: icon_02,
                    title: 'partnership.choose_option.tab_01.block.card_02.title',
                    text: 'partnership.choose_option.tab_01.block.card_02.text',
                },
                {
                    icon: icon_03,
                    title: 'partnership.choose_option.tab_01.block.card_03.title',
                    text: 'partnership.choose_option.tab_01.block.card_03.text',
                },
                {
                    icon: icon_04,
                    title: 'partnership.choose_option.tab_01.block.card_04.title',
                    text: 'partnership.choose_option.tab_01.block.card_04.text',
                },
            ],
        },
    },
    {
        image: image_02,
        button: 'partnership.choose_option.button_02',
        title: 'partnership.choose_option.tab_02.title',
        text: [
            'partnership.choose_option.tab_02.text_01',
            'partnership.choose_option.tab_02.text_02',
        ],
        list: [
            'partnership.choose_option.tab_02.list.li_01',
            'partnership.choose_option.tab_02.list.li_02',
            'partnership.choose_option.tab_02.list.li_03',
            'partnership.choose_option.tab_02.list.li_04',
            'partnership.choose_option.tab_02.list.li_05',
            'partnership.choose_option.tab_02.list.li_06',
        ],
        cards: [
            'partnership.choose_option.tab_02.cards.card_01.text',
            'partnership.choose_option.tab_02.cards.card_02.text',
            'partnership.choose_option.tab_02.cards.card_03.text',
            'partnership.choose_option.tab_02.cards.card_04.text',
        ],
    },
    {
        button: 'partnership.choose_option.button_03',
        title: 'partnership.choose_option.tab_03.title',
        subtitle: 'partnership.choose_option.tab_03.subtitle',
        text: [
            'partnership.choose_option.tab_03.text',
        ],
        list: [
            'partnership.choose_option.tab_03.list.li_01',
            'partnership.choose_option.tab_03.list.li_02',
            'partnership.choose_option.tab_03.list.li_03',
            'partnership.choose_option.tab_03.list.li_04',
            'partnership.choose_option.tab_03.list.li_05',
            'partnership.choose_option.tab_03.list.li_06',
            'partnership.choose_option.tab_03.list.li_07',
            'partnership.choose_option.tab_03.list.li_08',
            'partnership.choose_option.tab_03.list.li_09',
            'partnership.choose_option.tab_03.list.li_10',
            'partnership.choose_option.tab_03.list.li_11',
        ],
        subcards: [
            {
                title: 'partnership.choose_option.tab_03.subcards.card_01.title',
                text: 'partnership.choose_option.tab_03.subcards.card_01.text',
            },
            {
                title: 'partnership.choose_option.tab_03.subcards.card_02.title',
                text: 'partnership.choose_option.tab_03.subcards.card_02.text',
            },
            {
                title: 'partnership.choose_option.tab_03.subcards.card_03.title',
            },
        ],
    },
];

const ChooseOption = () => {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);
    const [canScroll, setCanScroll] = useState(false);

    const scrollToBlock = () => {
        const element = document.getElementById('partnershipChooseOption');
        element.scrollIntoView({
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        if (canScroll) {
            scrollToBlock();
        }

        if (active > 0) {
            setCanScroll(true);
            scrollToBlock();
        }
        // eslint-disable-next-line
    }, [active]);

    return (
        <section id="partnershipChooseOption" className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-64`}>
                    <Trans>
                        {t('partnership.choose_option.title')}
                    </Trans>
                </h2>
                <Buttons tabs={tabs} active={active} setActive={setActive} />
                <div className={classes.tab}>
                    <Tab tab={tabs[active]} />
                </div>
                <Buttons tabs={tabs} active={active} setActive={setActive} />
            </div>
        </section>
    );
};

export default ChooseOption;

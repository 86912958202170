import React from "react";
import classes from './GetTouch.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/HomePage/get_touch.webp';
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const GetTouch = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <Fade left>
                        <h2 className={`${classes.title} font-64`}>
                            {t('home.get_touch.title')}
                        </h2>
                    </Fade>
                    <h3 className={`${classes.subtitle} font-36`}>
                        {t('home.get_touch.subtitle')}
                    </h3>
                </div>
                <div className={classes.btn}>
                    <Button dark>
                        {t('btn.contact_us')}
                    </Button>
                </div>
                <Fade right>
                    <img className={classes.image} src={image} alt='' />
                </Fade>
            </div>
        </section>
    );
};

export default GetTouch;

import React from "react";
import classes from './Tab.module.scss';
import { Trans, useTranslation } from "react-i18next";

const Tab = ({ tab }) => {
    const { t } = useTranslation();
    return (
        <section className={classes.wrap}>
            <div className={`${classes.head} ${tab.image ? classes.headFlex : ''}`}>
                <div className={classes.content}>
                    <h4 className={`${classes.title} font-36`}>
                        {t(tab.title)}
                    </h4>
                    {tab.subtitle &&
                        <h5 className={`${classes.subtitle} font-24`}>
                            {t(tab.subtitle)}
                        </h5>
                    }
                    {tab.text &&
                        <div className={`${classes.text} font-16`}>
                            {tab.text.map((txt, txtIndex) =>
                                <p key={txtIndex}>
                                    {t(txt)}
                                </p>
                            )}
                        </div>
                    }
                    {tab.list.length <= 6 ?
                        <ul className={`${classes.list} font-16`}>
                            {tab.list.map((li, index) =>
                                <li key={index} className={classes.item}>
                                    {t(li)}
                                </li>
                            )}
                        </ul>
                        :
                        <ul className={`${classes.list} ${classes.listGrid} font-16`}>
                            <div className={classes.flex}>
                                {tab.list.slice(0, 5).map((li, index) => (
                                    <li key={index} className={classes.item}>
                                        {t(li)}
                                    </li>
                                ))}
                            </div>
                            <div className={classes.flex}>
                                {tab.list.slice(5, 11).map((li, index) => (
                                    <li key={index} className={classes.item}>
                                        {t(li)}
                                    </li>
                                ))}
                            </div>
                        </ul>
                    }
                </div>
                {tab.image &&
                    <div className={classes.image}>
                        <img src={tab.image} alt='' />
                    </div>
                }
            </div>
            {tab.cards &&
                <div className={classes.cardsWrap}>
                    <h2 className={`${classes.title} font-36`}>
                        {t('partnership.choose_option.how_it_works')}
                    </h2>
                    <div className={classes.cards}>
                        {tab.cards.map((card, index) =>
                            <div key={index} className={classes.card}>
                                <div className={`${classes.num} font-24`}>
                                    {t('partnership.banner.step')} {index + 1}
                                </div>
                                <p className={`${classes.cardText} font-20`}>
                                    <Trans>
                                        {t(card)}
                                    </Trans>
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            }
            {tab.block &&
                <div className={classes.block}>
                    <h2 className={`${classes.title} font-36`}>
                        {t(tab.block.title)}
                    </h2>
                    <div className={classes.cards}>
                        {tab.block.cards.map((card, index) =>
                            <div key={index} className={classes.card}>
                                <img className={classes.icon} src={card.icon} alt='' />
                                <div className={classes.content}>
                                    <h4 className={`${classes.subtitle} font-36-20`}>
                                        {t(card.title)}
                                    </h4>
                                    <p className={`${classes.text} font-16`}>
                                        {t(card.text)}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            }
            {tab.subcards &&
                <div className={classes.subcardsWrap}>
                    <h2 className={`${classes.title} font-36`}>
                        {t('partnership.choose_option.how_it_works')}
                    </h2>
                    <div className={classes.subcards}>
                        {tab.subcards.map((card, index) =>
                            <div key={index} className={classes.subcard}>
                                <div className={`${classes.subcardNum} font-24`}>
                                    0{index + 1}
                                </div>
                                <h4 className={`${classes.subcardTitle} font-24`}>
                                    {t(card.title)}
                                </h4>
                                {card.text &&
                                    <p className={`${classes.subcardText} font-16`}>
                                        {t(card.text)}
                                    </p>
                                }
                            </div>
                        )}
                    </div>
                </div>
            }
        </section>
    );
};

export default Tab;

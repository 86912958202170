import React from "react";
import classes from './Benefits.module.scss';
import { useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/PartnershipPage/Benefits/image_01.jpg';
import image_02 from '../../../assets/img/PartnershipPage/Benefits/image_02.jpg';
import image_03 from '../../../assets/img/PartnershipPage/Benefits/image_03.svg';
import { Fade } from "react-reveal";

const cards = [
    {
        title: 'partnership.card_01.title',
        text: 'partnership.card_01.text',
    },
    {
        title: 'partnership.card_02.title',
        text: 'partnership.card_02.text',
    },
    {
        image: image_01,
        title: 'partnership.card_03.title',
        text: 'partnership.card_03.text',
    },
    {
        image: image_02,
        title: 'partnership.card_04.title',
        text: 'partnership.card_04.text',
    },
    {
        title: 'partnership.card_05.title',
        text: 'partnership.card_05.text',
    },
    {
        image: image_03,
        title: 'partnership.card_06.title',
        text: 'partnership.card_06.text',
    },
];

const Benefits = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-36`}>
                    {t('partnership.title')}
                </h2>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <Fade
                            key={index}
                            left={(index === 0 || index === 3 || index === 4) ? true : false}
                            right={(index === 1 || index === 2 || index === 5) ? true : false}
                        >
                            <div className={classes.card}>
                                <h4 className={`${classes.cardTitle} font-32`}>
                                    {t(card.title)}
                                </h4>
                                <p className={`${classes.cardText} font-16`}>
                                    {t(card.text)}
                                </p>
                                {card.image &&
                                    <img className={classes.cardImage} src={card.image} alt='' />
                                }
                            </div>
                        </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Benefits;
